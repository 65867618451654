"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dateTimeShiftsToWorkHours = exports.dateTimeShiftToWorkHours = void 0;
const date_1 = require("@bemlo/date");
// TODO(): can we use DayjsShift instead?
const dateTimeShiftToWorkHours = (shift) => {
    const startMoment = shift.startDateTime instanceof date_1.dayjsEn
        ? shift.startDateTime
        : (0, date_1.dayjsEn)(shift.startDateTime);
    const endMoment = shift.endDateTime instanceof date_1.dayjsEn
        ? shift.endDateTime
        : (0, date_1.dayjsEn)(shift.endDateTime);
    const shiftDuration = date_1.dayjsEn.duration(endMoment.diff(startMoment));
    const breakDuration = date_1.dayjsEn.duration(shift.breakTime, 'minutes');
    return shiftDuration.subtract(breakDuration).asHours();
};
exports.dateTimeShiftToWorkHours = dateTimeShiftToWorkHours;
const dateTimeShiftsToWorkHours = (shifts) => {
    return shifts.reduce((acc, shift) => acc + (0, exports.dateTimeShiftToWorkHours)(shift), 0);
};
exports.dateTimeShiftsToWorkHours = dateTimeShiftsToWorkHours;
