"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.countryToTz = void 0;
const enums_1 = require("@bemlo/enums");
const countryToTz = (country) => {
    switch (country) {
        case enums_1.Country.SWEDEN:
            return 'Europe/Stockholm';
        case enums_1.Country.NORWAY:
            return 'Europe/Oslo';
        case enums_1.Country.DENMARK:
            return 'Europe/Copenhagen';
        case enums_1.Country.FINLAND:
            return 'Europe/Helsinki';
        case enums_1.Country.FRANCE:
            return 'Europe/Paris';
        case enums_1.Country.GERMANY:
            return 'Europe/Berlin';
        case enums_1.Country.SPAIN:
            return 'Europe/Madrid';
        case enums_1.Country.UNITED_KINGDOM:
            return 'Europe/London';
        case enums_1.Country.BELGIUM:
            return 'Europe/Brussels';
        default:
            throw new Error(`Invalid country: ${country}`);
    }
};
exports.countryToTz = countryToTz;
