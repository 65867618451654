"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateShiftAvailabilityOverlap = void 0;
exports.calculateOverlap = calculateOverlap;
const max = (...dates) => {
    return dates.reduce((max, current) => (current > max ? current : max));
};
const min = (...dates) => {
    return dates.reduce((min, current) => (current < min ? current : min));
};
// Function to merge overlapping intervals
const mergeIntervals = (intervals) => {
    if (!intervals.length)
        return [];
    // Sort intervals by start time
    intervals.sort((a, b) => a.startDateTime.unix() - b.startDateTime.unix());
    const merged = [intervals[0]];
    for (let i = 1; i < intervals.length; i++) {
        const lastMerged = merged[merged.length - 1];
        const current = intervals[i];
        if (lastMerged.endDateTime >= current.startDateTime) {
            // There is an overlap, merge intervals
            lastMerged.endDateTime = max(lastMerged.endDateTime, current.endDateTime);
        }
        else {
            merged.push(current);
        }
    }
    return merged;
};
// Function to calculate overlap in minutes
function calculateOverlap(shift, intervals) {
    let totalOverlap = 0;
    intervals.forEach((interval) => {
        const start = max(shift.startDateTime, interval.startDateTime);
        const end = min(shift.endDateTime, interval.endDateTime);
        if (start.isBefore(end)) {
            totalOverlap += end.diff(start, 'minute');
        }
    });
    return totalOverlap;
}
const calculateShiftAvailabilityOverlap = (shift, availability) => {
    const availableIntervals = availability.filter((interval) => interval.isAvailable);
    const unavailableIntervals = availability.filter((interval) => !interval.isAvailable);
    const mergedAvailableIntervals = mergeIntervals(availableIntervals);
    const mergedUnavailableIntervals = mergeIntervals(unavailableIntervals);
    const shiftDuration = shift.endDateTime.diff(shift.startDateTime, 'minute');
    const availableOverlap = calculateOverlap(shift, mergedAvailableIntervals);
    const unavailableOverlap = calculateOverlap(shift, mergedUnavailableIntervals);
    const availableOverlapPercentage = (availableOverlap / shiftDuration) * 100;
    const unavailableOverlapPercentage = (unavailableOverlap / shiftDuration) * 100;
    return {
        availableOverlap: availableOverlapPercentage,
        unavailableOverlap: unavailableOverlapPercentage,
    };
};
exports.calculateShiftAvailabilityOverlap = calculateShiftAvailabilityOverlap;
