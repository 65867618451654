"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorCodes = void 0;
var ErrorCodes;
(function (ErrorCodes) {
    ErrorCodes["UNAUTHORIZED"] = "UNAUTHORIZED";
    ErrorCodes["NO_IDENTITY_FOR_CREDENTIALS"] = "NO_IDENTITY_FOR_CREDENTIALS";
    ErrorCodes["CONTEXT_CREATION_FAILED"] = "CONTEXT_CREATION_FAILED";
    ErrorCodes["TRY_SSO"] = "TRY_SSO";
    ErrorCodes["INVALID_OR_EXPIRED_TOKEN"] = "INVALID_OR_EXPIRED_TOKEN";
    ErrorCodes["PHONE_NUMBER_IN_USE"] = "PHONE_NUMBER_IN_USE";
    ErrorCodes["EMAIL_IN_USE"] = "EMAIL_IN_USE";
})(ErrorCodes || (exports.ErrorCodes = ErrorCodes = {}));
