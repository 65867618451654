"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDaysInRange = void 0;
const lodash_1 = require("lodash");
const react_1 = require("react");
const date_1 = require("@bemlo/date");
const translate_1 = require("../../../../translate");
/**
 * Prevents an infinite loop if the input is bad (should not happen).
 */
const MAX_DAYS = 366;
const useDaysInRange = (startsAt, endsAt, country, roundTo = 'week') => {
    const t = (0, translate_1.useTranslations)();
    const [holidays, setHolidays] = (0, react_1.useState)({});
    (0, react_1.useEffect)(() => {
        let isMounted = true; // To prevent state updates after unmount
        const loadHolidays = async () => {
            if (country) {
                // Dynamically import the getHolidays function
                const { getHolidays } = await Promise.resolve().then(() => __importStar(require('@bemlo/holidays')));
                const holidaysData = await getHolidays(country, t.$date.unix(startsAt), t.$date.unix(endsAt));
                if (isMounted) {
                    setHolidays(holidaysData.reduce((acc, holiday) => {
                        acc[holiday.date.format(date_1.ISO_DATE_FORMAT)] = holiday;
                        return acc;
                    }, {}));
                }
            }
            else {
                if (isMounted) {
                    setHolidays({});
                }
            }
        };
        void loadHolidays();
        return () => {
            isMounted = false;
        };
    }, [country, startsAt, endsAt]);
    return (0, react_1.useMemo)(() => {
        const now = (0, date_1.dayjsEn)();
        const startDate = t.$date.unix(startsAt);
        const endDate = t.$date.unix(endsAt);
        const startDateRounded = roundTo ? startDate.startOf(roundTo) : startDate;
        const endDateRounded = roundTo ? endDate.endOf(roundTo) : endDate;
        const days = [];
        let currentDate = startDateRounded;
        let i = 0;
        while ((currentDate.isSame(endDateRounded) ||
            currentDate.isBefore(endDateRounded)) &&
            i < MAX_DAYS) {
            const date = currentDate;
            const dateISO = date.format(date_1.ISO_DATE_FORMAT);
            const dateLong = date.format('ddd **D/M**');
            const dateShort = date.format('**D/M**');
            const week = date.format('GGGG-WW');
            const weekNumber = date.isoWeek();
            const isToday = date.isSame(now, 'day');
            const holiday = holidays[dateISO] ?? null;
            const isWeekend = date.isoWeekday() >= 6;
            const withinRange = !date.isBefore(startDate) && !date.isAfter(endDate);
            days.push({
                date,
                dateISO,
                dateLong,
                dateShort,
                week,
                weekNumber,
                isToday,
                holiday,
                isWeekend,
                withinRange,
            });
            currentDate = currentDate.add(1, 'day');
            i++;
        }
        // Group days by week and sort the weeks
        const weeks = Object.entries((0, lodash_1.groupBy)(days, (day) => day.week))
            .sort(([weekA], [weekB]) => weekA.localeCompare(weekB))
            .map(([_, daysInWeek]) => ({
            days: daysInWeek,
            key: daysInWeek[0].week,
            number: daysInWeek[0].weekNumber,
        }));
        return [days, weeks];
    }, [startsAt, endsAt, t, holidays]);
};
exports.useDaysInRange = useDaysInRange;
