"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dayjsShiftsToQuarters = exports.dayjsShiftToQuarters = exports.shiftsToQuarters = exports.shiftToQuarters = void 0;
const utils_1 = require("./utils");
const QUARTERS_IN_DAY = 96;
const EMPTY_DAY = () => Array.from({ length: QUARTERS_IN_DAY }, () => 0);
const minuteOfDay = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
};
const shiftTimesToIndex = (shift) => {
    const startIndex = Math.floor(minuteOfDay(shift.startTime) / 15);
    const length = Math.floor((0, utils_1.timesToLength)(shift, 'minutes') / 15);
    return { startIndex, length };
};
const shiftToQuarters = (shift) => {
    const quarters = EMPTY_DAY();
    const { startIndex, length } = shiftTimesToIndex(shift);
    for (let i = startIndex; i < startIndex + length; i++) {
        const indexToUpdate = i % 96;
        quarters[indexToUpdate] += 1;
    }
    return quarters;
};
exports.shiftToQuarters = shiftToQuarters;
const shiftsToQuarters = (shifts) => {
    const quarters = EMPTY_DAY();
    for (const shift of shifts) {
        const { startIndex, length } = shiftTimesToIndex(shift);
        for (let i = startIndex; i < startIndex + length; i++) {
            const indexToUpdate = i % 96;
            quarters[indexToUpdate] += 1;
        }
    }
    return quarters;
};
exports.shiftsToQuarters = shiftsToQuarters;
const dayjsShiftToIndex = (shift) => {
    const startIndex = shift.startDateTime.hour() * 60 + shift.startDateTime.minute();
    const length = shift.endDateTime.diff(shift.startDateTime, 'minutes');
    return { startIndex, length };
};
const dayjsShiftToQuarters = (shift) => {
    const quarters = EMPTY_DAY();
    const { startIndex, length } = dayjsShiftToIndex(shift);
    for (let i = startIndex; i < startIndex + length; i += 15) {
        const indexToUpdate = i % 1440;
        quarters[indexToUpdate / 15] += 1;
    }
    return quarters;
};
exports.dayjsShiftToQuarters = dayjsShiftToQuarters;
const dayjsShiftsToQuarters = (shifts) => {
    const quarters = EMPTY_DAY();
    for (const shift of shifts) {
        const { startIndex, length } = dayjsShiftToIndex(shift);
        for (let i = startIndex; i < startIndex + length; i += 15) {
            const indexToUpdate = i % 1440;
            quarters[indexToUpdate / 15] += 1;
        }
    }
    return quarters;
};
exports.dayjsShiftsToQuarters = dayjsShiftsToQuarters;
