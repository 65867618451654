"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.serializeShift = exports.serializeOnCallTime = void 0;
const serializeOnCallTime = (onCallTime) => {
    const { type, startTime, endTime } = onCallTime;
    return `${type}-${startTime}-${endTime}`;
};
exports.serializeOnCallTime = serializeOnCallTime;
const serializeShift = (shift, options = {}) => {
    const { activity, date, startTime, endTime, breakTime, comment, onCallTime } = shift;
    const { rowKey, scheduleResourceId } = options;
    const parts = [];
    parts.push(activity, date, startTime, endTime, breakTime, comment);
    if (onCallTime) {
        parts.push((0, exports.serializeOnCallTime)(onCallTime));
    }
    if (typeof scheduleResourceId === 'string') {
        parts.push(scheduleResourceId);
    }
    if (typeof rowKey === 'number') {
        parts.push(rowKey);
    }
    return parts.join('-');
};
exports.serializeShift = serializeShift;
