"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRestErrorsMap = exports.getRestErrors = void 0;
const lodash_1 = require("lodash");
const date_1 = require("@bemlo/date");
const utils_1 = require("@bemlo/utils");
const DAY_SLOT_LENGTH = 24;
const WEEK_SLOT_LENGTH = 7 * DAY_SLOT_LENGTH;
const getActiveTime = (shifts) => {
    return shifts.flatMap(({ startDateTime, endDateTime, onCallTime }) => {
        if (!onCallTime) {
            return [{ startsAt: startDateTime, endsAt: endDateTime }];
        }
        const onCallTimeInStart = startDateTime.isSame(onCallTime.startDateTime, 'minute');
        const onCallTimeInEnd = endDateTime.isSame(onCallTime.endDateTime, 'minute');
        // Only on call, no active time
        if (onCallTimeInStart && onCallTimeInEnd) {
            return [];
        }
        if (onCallTimeInStart) {
            return [
                {
                    startsAt: onCallTime.endDateTime,
                    endsAt: endDateTime,
                },
            ];
        }
        if (onCallTimeInEnd) {
            return [
                {
                    startsAt: startDateTime,
                    endsAt: onCallTime.startDateTime,
                },
            ];
        }
        // If the on call time is the middle of the shift
        return [
            {
                startsAt: startDateTime,
                endsAt: onCallTime.startDateTime,
            },
            {
                startsAt: onCallTime.endDateTime,
                endsAt: endDateTime,
            },
        ];
    });
};
const assignActiveTimesToSlots = (slots, activeTimes, slotLength) => {
    const slotStartTime = slots[0].startsAt;
    for (const activeTime of activeTimes) {
        const startDiffHours = activeTime.startsAt.diff(slotStartTime, 'hours', true);
        const endDiffHours = activeTime.endsAt.diff(slotStartTime, 'hours', true);
        const startIndex = Math.floor(startDiffHours / slotLength);
        const endIndex = Math.floor(endDiffHours / slotLength);
        for (let i = startIndex; i <= endIndex; i++) {
            if (i >= 0 && i < slots.length) {
                slots[i].activeTime.push(activeTime);
            }
        }
    }
};
const calculateTimeSlotMaxRestTime = (slots, fullRest) => {
    for (let slotI = 0; slotI < slots.length; slotI++) {
        const slot = slots[slotI];
        // No active time, chill!
        if (!slot.activeTime.length) {
            slots[slotI].restTime = fullRest;
            continue;
        }
        let restTime = slots[slotI].restTime;
        for (let activeTimeI = 0; activeTimeI < slot.activeTime.length; activeTimeI++) {
            const prevActiveTime = activeTimeI > 0 && slot.activeTime[activeTimeI - 1];
            const activeTime = slot.activeTime[activeTimeI];
            const nextActiveTime = slot.activeTime[activeTimeI + 1];
            // first active time and it starts after the slot begins
            if (!prevActiveTime && activeTime.startsAt.isAfter(slot.startsAt)) {
                const restBeforeActiveTime = activeTime.startsAt.diff(slot.startsAt, 'hours', true);
                restTime = (0, lodash_1.max)([restBeforeActiveTime, restTime]) ?? 0;
            }
            if (!nextActiveTime && activeTime.endsAt.isBefore(slot.endsAt)) {
                const restTimeBeforeEnd = slot.endsAt.diff(activeTime.endsAt, 'hours', true);
                restTime = (0, lodash_1.max)([restTimeBeforeEnd, restTime]) ?? 0;
            }
            const restTimeBetweenActiveTimes = nextActiveTime?.startsAt.diff(activeTime.endsAt, 'hours') ?? 0;
            restTime = (0, lodash_1.max)([restTime, restTimeBetweenActiveTimes]) ?? 0;
        }
        slots[slotI].restTime = restTime;
    }
};
const getSlots = (firstSlotStartsAt, numberOfSlots, slotLength, activeTimes) => {
    const slots = Array.from({ length: numberOfSlots }).map((_, i) => {
        const startsAt = firstSlotStartsAt.add(i * slotLength, 'hours');
        const endsAt = startsAt.add(slotLength, 'hours');
        return {
            date: startsAt.format(date_1.ISO_DATE_FORMAT),
            startsAt,
            endsAt,
            restTime: 0,
            activeTime: [],
        };
    });
    // These are a bit bad since they updating by reference...
    assignActiveTimesToSlots(slots, activeTimes, slotLength);
    calculateTimeSlotMaxRestTime(slots, slotLength);
    return slots;
};
const getDailyRestErrors = (activeTimes, rules) => {
    const startsAt = activeTimes[0].startsAt;
    const endsAt = activeTimes[activeTimes.length - 1].endsAt;
    const firstSlotStartsAt = startsAt.time(rules.dayBreak).subtract(1, 'day');
    const numberOfSlots = endsAt.diff(firstSlotStartsAt, 'days') + 1;
    const slots = getSlots(firstSlotStartsAt, numberOfSlots, DAY_SLOT_LENGTH, activeTimes);
    const { recommendedDailyRest: RECOMMENDED_DAILY_REST_TIME, minimumDailyRest: MINIMUM_DAILY_REST_TIME, } = rules;
    // If the resource only had the minimum rest time the next day we need to compensate
    const REST_WITH_MAXIMUM_COMPENSATION = RECOMMENDED_DAILY_REST_TIME +
        (RECOMMENDED_DAILY_REST_TIME - MINIMUM_DAILY_REST_TIME);
    const isRecommendedRest = (slot) => {
        return slot.restTime >= RECOMMENDED_DAILY_REST_TIME;
    };
    const isMinimumRest = (slot) => {
        return slot.restTime >= MINIMUM_DAILY_REST_TIME;
    };
    return slots
        .map((slot, i) => {
        // If the rest time is recommended + maximum compensation you can accrue we know it's fine
        if (slot.restTime >= REST_WITH_MAXIMUM_COMPENSATION) {
            return null;
        }
        if (!isMinimumRest(slot)) {
            return {
                date: new Set([slot.date]),
                severity: 'error',
                type: 'no-day-rest',
                restTime: slot.restTime,
            };
        }
        // Control that if the previous day had the minimum rest we compensate on this day
        const prevSlotRestTime = i > 0 ? slots[i - 1].restTime : RECOMMENDED_DAILY_REST_TIME;
        if (!isRecommendedRest({ restTime: prevSlotRestTime })) {
            const diffWithRecommendedRest = RECOMMENDED_DAILY_REST_TIME - prevSlotRestTime;
            // Make sure current slot has the recommended rest AND the diff from the prev day
            if (slot.restTime <
                RECOMMENDED_DAILY_REST_TIME + diffWithRecommendedRest) {
                return {
                    date: new Set([slot.date]),
                    severity: 'error',
                    type: 'no-compensating-rest',
                    restTime: slot.restTime,
                };
            }
        }
        if (!isRecommendedRest(slot)) {
            return {
                date: new Set([slot.date]),
                severity: 'warning',
                type: 'reduced-day-rest',
                restTime: slot.restTime,
            };
        }
        return null;
    })
        .filter(utils_1.isTruthy);
};
const getWeeklyRestErrors = (activeTimes, rules) => {
    const weekBreak = rules.weekBreak.split(' ');
    const weekDay = parseInt(weekBreak[0]);
    const time = weekBreak[1];
    const startsAt = activeTimes[0].startsAt;
    const endsAt = activeTimes[activeTimes.length - 1].endsAt;
    let firstSlotStartsAt = startsAt.isoWeekday(weekDay).time(time);
    // Make sure the first active time is part of the first slot
    if (firstSlotStartsAt.isAfter(startsAt)) {
        firstSlotStartsAt = firstSlotStartsAt.subtract(1, 'week');
    }
    const numberOfSlots = endsAt.diff(firstSlotStartsAt, 'weeks') + 1;
    const slots = getSlots(firstSlotStartsAt, numberOfSlots, WEEK_SLOT_LENGTH, activeTimes);
    return slots
        .map((slot) => {
        if (slot.restTime < rules.minimumWeeklyRest) {
            return {
                date: new Set([slot.date]),
                restTime: slot.restTime,
                severity: 'error',
                type: 'no-week-rest',
            };
        }
        return null;
    })
        .filter(utils_1.isTruthy);
};
// TODO(ef): should we add regex for day and week breaks? How to handle that? Notify or throw error?
const getRestErrors = (unsortedShifts, rules) => {
    if (!unsortedShifts.length)
        return [];
    const shifts = (0, lodash_1.sortBy)(unsortedShifts, 'startDateTime');
    const activeTimes = getActiveTime(shifts);
    return [
        ...getDailyRestErrors(activeTimes, rules),
        ...getWeeklyRestErrors(activeTimes, rules),
    ];
};
exports.getRestErrors = getRestErrors;
const getRestErrorsMap = (unsortedShifts, rules) => {
    const errors = getRestErrors(unsortedShifts, rules);
    if (!errors.length)
        return {};
    return errors.reduce((acc, error) => {
        for (const date of error.date) {
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(error);
        }
        return acc;
    }, {});
};
exports.getRestErrorsMap = getRestErrorsMap;
